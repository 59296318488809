<template>
  <div>
    <v-card>
      <v-toolbar flat color="primary" dark>
        <v-toolbar-title>Settings</v-toolbar-title>
      </v-toolbar>
      <v-tabs vertical>
        <v-tab>SMS Settings</v-tab>
        <v-tab>Active Drivers</v-tab>
        <v-tab-item><SmsSettings /> </v-tab-item>
        <v-tab-item><ActiveUsers /> </v-tab-item>
      </v-tabs>
    </v-card>
  </div>
</template>
<script>
import SmsSettings from "./Settings/SmsSettings.vue";
import ActiveUsers from "./Settings/ActiveUsers.vue";

export default {
  name: "Settings",
  components: { SmsSettings, ActiveUsers },
  data: () => ({
    dialog: false,
    dialogDelete: false,
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Add New FTP" : "Edit FTP";
    },
  },

  created() {
    this.initialize();
  },
  methods: {
    initialize() {},
    editItem(item) {
      this.editedIndex = this.ftps.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.ftps.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    save() {
      if (this.editedIndex > -1) {
        SettingsDataService.updateFtpConfig(this.editedItem).then(
          (response) => {}
        );
        Object.assign(this.ftps[this.editedIndex], this.editedItem);
        this.$toast.success("FTP config edited successfully.", "Success", {
          position: "topRight",
        });
      } else {
        SettingsDataService.createFtpConfig(this.editedItem).then(
          (response) => {
            this.ftps.push(response.data);
            this.$toast.success("FTP config added successfully.", "Success", {
              position: "topRight",
            });
          }
        );
      }
      this.close();
    },
    deleteItemConfirm() {
      SettingsDataService.deleteFtpConfig(this.editedItem.id).then(
        (response) => {
          if (response.status === 200) {
            this.$toast.success("Product deleted successfully.", "Success", {
              position: "topRight",
            });
            this.ftps.splice(this.editedIndex, 1);
          } else {
            this.$toast.error(
              "Something went wrong. please try again later",
              "Error",
              {
                position: "topRight",
              }
            );
          }
        }
      );
      this.closeDelete();
    },
  },
};
</script>

<template>
  <v-card flat>
    <v-card-text>
      <v-col class="text-right ma-0 pa-0">
        <app-btn class="mx-2" @click="dialog = true">
          <v-icon>mdi-view-grid-plus</v-icon> Add SMS Setting
        </app-btn>
      </v-col>
      <v-data-table
        :headers="headers"
        :items="smsSettings"
        :footer-props="{
          showFirstLastPage: true,
          'items-per-page-options': [5, 10, 15],
        }"
      >
        <template v-slot:top>
          <v-dialog v-model="dialog" max-width="500px">
            <v-card>
              <v-card-title>
                <span class="headline">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-form ref="OrderForm">
                    <v-row>
                      <v-col cols="12">
                        <v-text-field v-model="editedItem.name" label="Name" />
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          v-model="editedItem.description"
                          label="Description"
                        />
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          v-model="editedItem.value"
                          label="Value"
                        />
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          v-model="editedItem.message"
                          label="Message"
                        />
                      </v-col>
                      <v-col cols="12">
                        <v-select
                          v-model="editedItem.active"
                          :items="[
                            { text: 'True', value: true },
                            { text: 'False', value: false },
                          ]"
                          item-text="text"
                          item-value="value"
                          label="Active"
                        />
                      </v-col>
                    </v-row>
                  </v-form>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer />
                <v-btn color="blue darken-1" text @click="close">
                  Cancel
                </v-btn>
                <v-btn color="blue darken-1" text @click="save"> Save </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="headline">
                Are you sure you want to delete this item?
              </v-card-title>
              <v-card-actions>
                <v-spacer />
                <v-btn color="blue darken-1" text @click="closeDelete">
                  Cancel
                </v-btn>
                <v-btn color="blue darken-1" text @click="deleteItemConfirm">
                  OK
                </v-btn>
                <v-spacer />
              </v-card-actions>
            </v-card>
          </v-dialog>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon medium color="info" class="mr-2" @click="editItem(item)">
            mdi-pencil
          </v-icon>
          <v-icon medium color="error" @click="deleteItem(item)">
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  name: "Settings",
  data: () => ({
    dialog: false,
    dialogDelete: false,
    headers: [
      {
        text: "Name",
        align: "start",
        value: "name",
      },
      { text: "Description", value: "description" },
      { text: "Value", value: "value" },
      { text: "Message", value: "message" },
      { text: "Active", value: "active" },
      { text: "Actions", align: "end", value: "actions" },
    ],
    smsSettings: [],
    editedIndex: -1,
    editedItem: {
      name: "",
      description: "",
      value: "",
      message: "",
      active: true,
    },
    defaultItem: {
      name: "",
      description: "",
      value: "",
      message: "",
      active: true,
    },
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? "Add New SMS Setting"
        : "Edit SMS Setting";
    },
  },

  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.$http.get("/SmsSetting").then((response) => {
        this.smsSettings = response.data;
      }).catch(() => {});
    },
    editItem(item) {
      this.editedIndex = this.smsSettings.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.smsSettings.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    save() {
      if (this.editedIndex > -1) {
        let index = this.editedIndex;
        this.$http
          .put(`/SmsSetting/${this.editedItem.id}`, this.editedItem)
          .then((response) => {
            if (response.data.success) {
              this.smsSettings.splice(index, 1, response.data.data);
              this.$toast.success(
                "Sms settings updated successfully.",
                "Success",
                {
                  position: "topRight",
                }
              );
            } else {
              this.$toast.error("Something went wrong.", "Error", {
                position: "topRight",
              });
            }
          }).catch(() => {});
      } else {
        this.$http.post("/SmsSetting", this.editedItem).then((response) => {
          this.smsSettings.unshift(response.data);
        }).catch(() => {});
      }
      this.close();
    },
    deleteItemConfirm() {
      let index = this.editedIndex;
      this.$http
        .delete(`/SmsSetting/${this.editedItem.id}`)
        .then((response) => {
          if (response.data.success) {
            this.$toast.success(
              "Sms setting deleted successfully.",
              "Success",
              {
                position: "topRight",
              }
            );
            this.smsSettings.splice(index, 1);
          } else {
            this.$toast.error(
              "Something went wrong. please try again later",
              "Error",
              {
                position: "topRight",
              }
            );
          }
        }).catch(() => {});
      this.closeDelete();
    },
  },
};
</script>
<template>
  <v-card flat>
    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="items"
        :footer-props="{
          showFirstLastPage: true,
          'items-per-page-options': [5, 10, 15],
        }"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon medium color="info" class="mr-2" @click="forceLogout(item)">
            mdi-logout
          </v-icon>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  name: "Settings",
  data: () => ({
    dialog: false,
    dialogDelete: false,
    headers: [
      {
        text: "Username",
        align: "start",
        value: "username",
      },
      { text: "Actions", align: "end", value: "actions" },
    ],
    items: [],
    editedIndex: -1,
    editedItem: {
      name: "",
      description: "",
      value: "",
      message: "",
      active: true,
    },
    defaultItem: {
      name: "",
      description: "",
      value: "",
      message: "",
      active: true,
    },
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? "Add New SMS Setting"
        : "Edit SMS Setting";
    },
  },

  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.$http
        .get("/User/OnlineUsers")
        .then((response) => {
          this.items = response.data.map((x) => {
            return { username: x };
          });
        })
        .catch(() => {});
    },
    forceLogout(item) {
      this.$signalR
        .forceLogoutUser(item.username)
        .then(() => {
          let index = this.items.findIndex((x) => x.username === item.username);
          this.items.splice(index, 1);
          this.$toast.success("User logged out successfully.", "Success", {
            position: "topRight",
          });
        })
        .catch((err) => {
          console.log(err);
          this.$toast.error("Something went wrong.", "Error", {
            position: "topRight",
          });
        });
      // this.$http
      //   .get("/User/ForceLogoutUser", { params: { username: item.username } })
      //   .then((response) => {
      //     if (response.data.success) {
      //       let index = this.items.findIndex(
      //         (x) => x.username === item.username
      //       );
      //       this.items.splice(index, 1);
      //       this.$toast.success("User logged out successfully.", "Success", {
      //         position: "topRight",
      //       });
      //     } else {
      //       this.$toast.error("Something went wrong.", "Error", {
      //         position: "topRight",
      //       });
      //     }
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //   });
    },
    editItem(item) {
      this.editedIndex = this.smsSettings.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.smsSettings.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    save() {
      if (this.editedIndex > -1) {
        let index = this.editedIndex;
        this.$http
          .put(`/SmsSetting/${this.editedItem.id}`, this.editedItem)
          .then((response) => {
            if (response.data.success) {
              this.smsSettings.splice(index, 1, response.data.data);
              this.$toast.success(
                "Sms settings updated successfully.",
                "Success",
                {
                  position: "topRight",
                }
              );
            } else {
              this.$toast.error("Something went wrong.", "Error", {
                position: "topRight",
              });
            }
          })
          .catch(() => {});
      } else {
        this.$http
          .post("/SmsSetting", this.editedItem)
          .then((response) => {
            this.smsSettings.unshift(response.data);
          })
          .catch(() => {});
      }
      this.close();
    },
    deleteItemConfirm() {
      let index = this.editedIndex;
      this.$http
        .delete(`/SmsSetting/${this.editedItem.id}`)
        .then((response) => {
          if (response.data.success) {
            this.$toast.success(
              "Sms setting deleted successfully.",
              "Success",
              {
                position: "topRight",
              }
            );
            this.smsSettings.splice(index, 1);
          } else {
            this.$toast.error(
              "Something went wrong. please try again later",
              "Error",
              {
                position: "topRight",
              }
            );
          }
        })
        .catch(() => {});
      this.closeDelete();
    },
  },
};
</script>